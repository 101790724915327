<template>
    <base-layout>
        <template v-slot:sidebar>
            <app-sidebar-details title="Bulk options" @close-sidebar="closeSidebar">
                <bulk-options
                    :uuids="sip_trunk_uuids"

                    ref="bulk-options"

                    @close-sidebar="closeSidebar"
                    @saved="onSaved"
                />
            </app-sidebar-details>
        </template>

        <div class="page-sip-trunking-tools-common-tasks">
            <div class="row">
                <div class="col-12">
                    <app-error v-model="errors.show" :message="errors.message"></app-error>

                    <app-table
                        class="sip-trunks-table"

                        v-model="selected"
                        @change="onChangeSelected"

                        :sort-value="filter.sort"
                        :sort-options="sort"
                        @sort-change="onSortChange"

                        :cols="cols"
                        :rows="rows"

                        :loading="loading"
                    />

                    <div class="actions">
                        <app-pagination
                            v-if="pagination.total"

                            v-model="pagination.page"

                            :total="pagination.total"
                            :limit="pagination.limit"
                            :page-range="pagination.range"

                            @change="onPageChange"
                        />

                        <button class="btn btn-primary btn-bulk-edit" :disabled="is_bulk_edit_btn_disabled" @click="bulkEdit">Bulk edit</button>
                    </div>
                </div>
            </div>
        </div>
    </base-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import baseLayout from '@/views/layouts/base-layout'
import appSidebarDetails from '@/components/app-sidebar-details'

import bulkOptions from './bulk-options'

import appError from '@/components/app-error'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'

import errMessage from '@/helpers/errMessage'

export default {
    components: {
        baseLayout,
        appSidebarDetails,

        bulkOptions,

        appError,
        appTable,
        appPagination,
    },

    data() {
        return {
            filter: {
                sort: {
                    field: 'Username',
                    order: 'asc'
                },
            },

            sort: [
                { field: 'Username',    title: 'Username', },
                { field: 'Enabled',     title: 'Enabled',  },
                { field: 'PilotNumber', title: 'Pilot',    },
                // { field: 'Trunking',    title: 'Trunking', },
                // { field: 'MenuActive',  title: 'Menu',     },
            ],

            cols: [
                { key: 'Username',    title: 'Username',    sort: { field: 'Username'    }, type: 'checkbox', },
                { key: 'Enabled',     title: 'Enabled',     sort: { field: 'Enabled'     },                   },
                { key: 'PilotNumber', title: 'Pilot',       sort: { field: 'PilotNumber' },                   },
                { key: 'Description', title: 'Description',                                                   },
                // { key: 'Trunking',    title: 'Trunking',    sort: { field: 'Trunking'    },                   },
                // { key: 'MenuActive',  title: 'Menu',        sort: { field: 'MenuActive'  },                   },
            ],
            sip_trunks: [],

            selected: {},

            loading: false,

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            is_mobile_mode: false,

            errors: {
                show: false,
                message: '',
            },
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.handleWindowResize()

        window.addEventListener('resize', this.handleWindowResize)
    },

    methods: {
        init() {
            if (this.$route.params.page) {
                this.pagination.page = +this.$route.params.page
            }

            this.getSIPTrunks()
        },

        handleWindowResize() {
            this.is_mobile_mode = document.body.clientWidth <= this.$mobile_size
        },

        onSortChange(sort) {
            this.filter.sort = sort

            this.getSIPTrunks()
        },

        onPageChange(page) {
            this.getSIPTrunks(page)
        },

        getSIPTrunks(page) {
            this.loading = true

            const filter = {
                ...this.filter,
            }

            const params = {
                'SPID':                     this.current_spid,
                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize':   this.pagination.limit,
                'SearchOptions.SortBy':     filter.sort.field,
                'SearchOptions.SortDesc':   filter.sort.order === 'desc' ? 1 : 0,
            }

            this.$store.dispatch('api_siptrunk/GetSIPTrunksPaginated', params)
                .then(response => {
                    if (!response.SIPTrunks || !response.PageInfo) {
                        return Promise.reject(response)
                    }

                    this.sip_trunks = response.SIPTrunks

                    this.pagination.page = response.PageInfo.PageNumber
                    this.pagination.total = response.PageInfo.TotalItemCount

                    if (this.$route.params.page != this.pagination.page
                        && !(
                            this.$route.params.page === undefined
                            && this.pagination.page === 1
                        )
                    ) {
                        this.$router.push({
                            name: this.$route.name,
                            params: { page: this.pagination.page },
                            query: this.$route.query,
                            replace: true
                        })
                    }

                    this.errors = {
                        show: false,
                        message: '',
                    }

                    this.loading = false
                })
                .catch(error => {
                    this.errors = {
                        show: true,
                        message: errMessage(error),
                    }

                    this.sip_trunks = []

                    this.loading = false
                })
        },

        onChangeSelected() {
            this.$store.commit('saveBulkSIPTrunksByUUIDs', { sip_trunks: this.sip_trunks, bulk_uuids: this.selected })
        },

        bulkEdit() {
            if (this.is_mobile_mode) {
                this.$router.push({ name: 'sip-trunking-tools-common-tasks-bulk-options', params: { uuids: this.sip_trunk_uuids.join('&') } })
            } else {
                this.openSidebar()
            }
        },

        openSidebar() {
            this.$emit('open-sidebar')
        },

        closeSidebar() {
            this.$emit('close-sidebar')

            this.$refs['bulk-options'].reset()
        },

        onSaved() {
            this.selected = {}

            this.getSIPTrunks()
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
            '$mobile_size',
        ]),

        rows() {
            const rows = []

            for (let i = 0, len = this.sip_trunks.length; i < len; i++) {
                const sip_trunk = this.sip_trunks[i]

                rows.push({
                    uuid: sip_trunk.UUID,
                    Username: sip_trunk.Username ? sip_trunk.Username : '?' ,
                    Enabled: sip_trunk.Enabled ? 'True' : 'False',
                    PilotNumber: sip_trunk.PilotNumber ? sip_trunk.PilotNumber : '?' ,
                    Description: sip_trunk.Description ? sip_trunk.Description : '?' ,
                    Trunking: sip_trunk.Trunking ? 'True' : 'False',
                    MenuActive: sip_trunk.MenuActive ? 'True' : 'False',
                })
            }

            return rows
        },

        is_bulk_edit_btn_disabled() {
            return this.loading || Object.keys(this.selected).length == 0
        },

        sip_trunk_uuids() {
            return Object.keys(this.selected)
        },
    },

    beforeRouteLeave(to, from, next) {
        if (to.name != 'sip-trunking-tools-common-tasks-bulk-options') {
            this.$store.commit('clearBulkSIPTrunks')
        }
        
        next()
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleWindowResize)
    },
}
</script>

<style lang="scss">
.page-sip-trunking-tools-common-tasks {
    $btn-max-width: 245px;

    .app-error {
        margin-bottom: 24px;
    }

    .sip-trunks-table {
        @include table-cols-width((190px, 100px, 150px, 250px), true);
    }

    & > .row {
        & > .col-12 {
            & > .actions {
                display: flex;

                .app-pagination {
                    width: calc(100% - #{ $btn-max-width });
                    margin-top: 30px;
                }

                .btn-bulk-edit {
                    margin-top: 30px;
                    margin-left: auto;

                    max-width: $btn-max-width;
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .page-sip-trunking-tools-common-tasks {
        .sip-trunks-table {
            @include table-cols-width((190px, 80px, 150px, 250px), true);
        }

        & > .row {
            & > .col-12 {
                & > .actions {
                    flex-direction: column;

                    .app-pagination {
                        width: 100%;
                    }

                    .btn-bulk-edit {
                        margin-left: initial;
                    }
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .page-sip-trunking-tools-common-tasks {
        .sip-trunks-table {
            @include table-cols-width-mobile((137px, 137px), true);
        }

        & > .row {
            & > .col-12 {
                & > .actions {
                    .app-pagination,
                    .btn-bulk-edit {
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}
</style>