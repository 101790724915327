<template>
    <div id="page-sip-trunking-tools-common-tasks-bulk-options" :class="{ 'on-sidebar': on_sidebar }">
        <app-loader v-if="loading" fixed-on="desktop" />

        <div class="container">
            <div class="row">
                <div class="col-12" v-if="!on_sidebar">
                    <router-link class="btn btn-back-to" :to="back_to">Back to Common Tasks</router-link>

                    <h1 class="heading">Bulk options</h1>
                </div>

                <div class="col-12 col-tab-12">
                    <div class="board forwarding">
                        <h2 class="heading">Forwarding</h2>

                        <div class="form-container">
                            <div class="form-section">
                                <h5 class="heading">No Answer</h5>
                                <div class="form-group">
                                    <div class="form-controls">
                                        <app-checkbox
                                            :value="bulk_sip_trunk.CFNAEnabled"
                                            @input="changeBulkOption('CFNAEnabled', $event)"

                                            :partially-checked="bulk_sip_trunk.CFNAEnabled === undefined"

                                            :disabled="loading"
                                        >Enabled</app-checkbox>
                                    </div>

                                    <div class="form-controls">
                                        <app-input
                                            :value="bulk_sip_trunk.CFNARingTime"
                                            @input="changeBulkOption('CFNARingTime', $event)"

                                            label="Ring Time (sec)"

                                            :error="errors.CFNARingTime"
                                            :disabled="loading"
                                        />

                                        <app-input
                                            :value="bulk_sip_trunk.CFNADestination"
                                            @input="changeBulkOption('CFNADestination', $event)"

                                            label="Destination"

                                            :error="errors.CFNADestination"
                                            :disabled="loading"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="form-section">
                                <h5 class="heading">Busy</h5>
                                <div class="form-group">
                                    <div class="form-controls">
                                        <app-checkbox
                                            :value="bulk_sip_trunk.CFBEnabled"
                                            @input="changeBulkOption('CFBEnabled', $event)"

                                            :partially-checked="bulk_sip_trunk.CFBEnabled === undefined"

                                            :disabled="loading"
                                        >Enabled</app-checkbox>

                                        <app-input
                                            :value="bulk_sip_trunk.CFBDestination"
                                            @input="changeBulkOption('CFBDestination', $event)"

                                            label="Destination"

                                            :error="errors.CFBDestination"
                                            :disabled="loading"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="form-section">
                                <h5 class="heading">Always</h5>
                                <div class="form-group">
                                    <div class="form-controls">
                                        <app-checkbox
                                            :value="bulk_sip_trunk.CFAEnabled"
                                            @input="changeBulkOption('CFAEnabled', $event)"

                                            :partially-checked="bulk_sip_trunk.CFAEnabled === undefined"

                                            :disabled="loading"
                                        >Enabled</app-checkbox>

                                        <app-input
                                            :value="bulk_sip_trunk.CFADestination"
                                            @input="changeBulkOption('CFADestination', $event)"

                                            label="Destination"

                                            :error="errors.CFADestination"
                                            :disabled="loading"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="form-section">
                                <h5 class="heading">Not Registered</h5>
                                <div class="form-group">
                                    <div class="form-controls">
                                        <app-checkbox
                                            :value="bulk_sip_trunk.CFNREnabled"
                                            @input="changeBulkOption('CFNREnabled', $event)"

                                            :partially-checked="bulk_sip_trunk.CFNREnabled === undefined"

                                            :disabled="loading"
                                        >Enabled</app-checkbox>

                                        <app-input
                                            :value="bulk_sip_trunk.CFNRDestination"
                                            @input="changeBulkOption('CFNRDestination', $event)"

                                            label="Destination"

                                            :error="errors.CFNRDestination"
                                            :disabled="loading"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <app-error v-model="errors.save"></app-error>

                    <button class="btn btn-primary btn-save" :disabled="loading || !is_changed" @click="save">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'
import appCheckbox from '@/components/app-checkbox'

import errMessage from '@/helpers/errMessage'

import SipTrunkValidator from '@/validators/sip-trunk-validator'

const EMPTY_SIP_TRUNK_OPTIONS = {
    // CFNA ---> No Answer
    CFNAEnabled: null,
    CFNARingTime: null,
    CFNADestination: null,

    // CFB ---> Busy
    CFBEnabled: null,
    CFBDestination: null,

    // CFA ---> Always
    CFAEnabled: null,
    CFADestination: null,

    // CFNR ---> Not Registered
    CFNREnabled: null,
    CFNRDestination: null,
}

export default {
    props: {
        uuids: { type: Array, default: () => [] },
    },

    components: {
        appLoader,
        appError,
        appInput,
        appCheckbox,
    },

    data() {
        return {
            sip_trunk: {...EMPTY_SIP_TRUNK_OPTIONS},
            sip_trunk_common: {...EMPTY_SIP_TRUNK_OPTIONS},

            loading: false,

            referrer: null,
            on_sidebar: false,

            errors: {},
        }
    },

    mounted() {
        this.on_sidebar = this.$parent.$el.classList.contains('app-sidebar-details')

        this.init()
    },

    methods: {
        init() {
            this.loadSIPTrunks()
        },

        loadSIPTrunks() {
            if (this.sip_trunk_uuids.length) {
                this.loading = true

                this.$store.dispatch('loadBulkSIPTrunksByUUIDs', this.sip_trunk_uuids).then(() => {
                    if (this.bulk_sip_trunks_uuids.length) {
                        this.initBulkOptions()
                    } else {
                        this.exit()
                    }
                }).finally(() => {
                    this.loading = false
                })
            } else {
                this.exit()
            }
        },

        initBulkOptions() {
            this.sip_trunk_common = {...EMPTY_SIP_TRUNK_OPTIONS}

            for (const key in this.sip_trunk_common) {
                for (const uuid in this.bulk_sip_trunks) {
                    this.sip_trunk_common[key] = this.sip_trunk_common[key] === null
                        ? this.bulk_sip_trunks[uuid][key]
                        : this.bulk_sip_trunks[uuid][key] === this.sip_trunk_common[key]
                            ? this.sip_trunk_common[key]
                            : undefined
                }
            }
        },

        changeBulkOption(key, value) {
            if (key == 'CFNARingTime' && value) {
                value = +value
            }

            this.sip_trunk[key] = value === this.sip_trunk_common[key]
                ? null
                : value

            this.$delete(this.errors, key)
        },

        save() {
            this.loading = true

            const params = {
                SPID: this.current_spid,
                UUIDs: this.bulk_sip_trunks_uuids,
                ...this.changed_sip_trunk,
            }

            this.$store.dispatch('api_siptrunk/BulkEditSipTrunk', params).then(response => {
                this.exit(true)
            }).catch(error => {
                this.errors.save = errMessage(error)
            }).finally(() => {
                this.loading = false
            })
        },

        reset() {
            this.errors = {}
            this.sip_trunk = {...EMPTY_SIP_TRUNK_OPTIONS}
        },

        exit(is_saved) {
            this.reset()
            this.loading = false
            
            if (this.on_sidebar) {
                this.$emit('close-sidebar')

                if (is_saved) {
                    this.$emit('saved')
                }
            } else {
                this.$router.push({
                    ...this.back_to,
                    replace: true,
                })
            }
        },
    },

    watch: {
        sip_trunk_uuids() {
            this.loadSIPTrunks()
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
            'bulk_sip_trunks',
        ]),

        bulk_sip_trunk() {
            let bulk_sip_trunk = {...this.sip_trunk_common}

            for (const key in this.sip_trunk) {
                if (this.sip_trunk[key] !== null) {
                    bulk_sip_trunk[key] = this.sip_trunk[key]
                }
            }

            if (bulk_sip_trunk.CFNARingTime !== undefined) {
                bulk_sip_trunk.CFNARingTime = +bulk_sip_trunk.CFNARingTime || 0
            }

            return bulk_sip_trunk
        },

        changed_sip_trunk() {
            let changed_sip_trunk = {}

            for (const key in this.bulk_sip_trunk) {
                if (this.bulk_sip_trunk[key] !== this.sip_trunk_common[key]) {
                    changed_sip_trunk[key] = this.bulk_sip_trunk[key]
                }
            }

            return changed_sip_trunk
        },

        is_changed() {
            return Object.keys(this.changed_sip_trunk).length > 0
        },

        back_to() {
            return this.referrer && this.referrer.name == 'sip-trunking-tools-common-tasks'
                ? this.referrer
                : { name: 'sip-trunking-tools-common-tasks' }
        },

        sip_trunk_uuids() {
            return this.on_sidebar
                ? this.uuids
                : this.$route.params.uuids
                    ? this.$route.params.uuids.split('&')
                    : []
        },

        bulk_sip_trunks_uuids() {
            return Object.keys(this.bulk_sip_trunks)
        },
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.referrer = from
        })
    },

    beforeRouteLeave(to, from, next) {
        this.$store.commit('clearBulkSIPTrunks')
        next()
    },
}
</script>

<style lang="scss">
#page-sip-trunking-tools-common-tasks-bulk-options {
    margin: 24px 0 80px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .app-error {
        margin-bottom: 24px;
    }

    .board {
        margin-bottom: 30px;
        padding: 24px;

        background-color: var(--color-component-bg-primary);
        border-radius: $border-radius-primary;
        box-shadow: var(--box-shadow-secondary);

        h2 {
            &.heading {
                margin-bottom: 24px;
            }
        }

        &.forwarding {
            .form-container {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                margin-bottom: -40px;

                .form-section {
                    width: 100%;
                    max-width: calc(50% - 30px);
    
                    margin-bottom: 40px;

                    h5 {
                        &.heading {
                            margin-bottom: 24px;
                        }
                    }

                    .form-group {
                        .form-controls {
                            .app-checkbox {
                                margin-bottom: 24px;
                            }
                        }
                    }

                    &:nth-child(1) {
                        .form-group {
                            .form-controls {
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: space-between;

                                .app-input {
                                    &:nth-child(1) {
                                        max-width: calc(40% - 15px);
                                    }

                                    &:nth-child(2) {
                                        max-width: calc(60% - 15px);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.on-sidebar {
        margin: 40px 0 32px;

        .board {
            h2 {
                &.heading {
                    font-size: 24px;
                    line-height: 32px;
                }
            }

            &.forwarding {
                .form-container {
                    .form-section {
                        max-width: 100%;
                    }
                }
            }
        }

        button {
            &.btn-save {
                max-width: 100%;
            }
        }
    }
}

@media (max-width: $tablet-size) {
    #page-sip-trunking-tools-common-tasks-bulk-options {
        .board {
            &.forwarding {
                .form-container {
                    .form-section {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    #page-sip-trunking-tools-common-tasks-bulk-options {
        .board {
            margin-bottom: 16px;
            padding: 16px 8px;

            &.forwarding {
                .form-container {
                    margin-bottom: -30px;

                    .form-section {
                        margin-bottom: 30px;

                        &:nth-child(1) {
                            .form-group {
                                .form-controls {
                                    .app-input {
                                        &:nth-child(1),
                                        &:nth-child(2) {
                                            max-width: 100%;

                                            margin-bottom: 24px;
                                        }

                                        &:last-child {
                                            margin-bottom: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        button {
            &.btn-save {
                max-width: 100%;
            }
        }
    }
}
</style>